import { PageContextProps } from 'application/contexts/PageContext';
import { queryCmsContent } from './_api';

export type SiblingNavigationObject = { parentName: string; navigationItems: Navigation.NavigationItem[] };

export const getSiblingNavigationFromCms = async (parentId: number, pageContext: Partial<PageContextProps>): Promise<SiblingNavigationObject> => {
	const graphQLquery = `{
		page(id:${parentId}) {
			id
			name
			properties
			url
			children {
			   documentType
			   createDate
			   properties
			   id
			   name
			   url
			}
		}
   }`;

	const results = await queryCmsContent({
		query: graphQLquery,
		pageContext,
		rewriteUrl: '/cms-content?path=ignore',
		requestType: 'siblingNavigation',
	});

	const navigationItems: Navigation.NavigationItem[] =
		results?.page?.children?.map((page) => {
			return {
				id: page.id,
				name: page.name,
				url: page.url,
				addToHeader: page.properties?.addToHeader ? true : false,
				hidden: page.properties?.umbracoNaviHide ? true : false,
				highlighted: page.properties?.highlighted ? true : false,
				title: page.properties?.navigationTitle,
				description: page.properties?.navigationDescription,
				// TODO if needed we can expand the graphQLquery and add 'descendants: page.children,' here
			};
		}) || [];

	return {
		parentName: results?.page?.properties?.navigationTitle ? results?.page?.properties?.navigationTitle : results?.page?.name,
		navigationItems: navigationItems,
	};
};
