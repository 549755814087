import { Aside } from 'designsystem/components/4-habitats/Aside';
import { ExcludedFromCludo } from 'helpers/ExcludedFromCludo';
import React from 'react';

export const AsideFeature = ({ children }: { children: React.ReactNode }) => {
	return (
		<ExcludedFromCludo>
			<Aside>{children}</Aside>
		</ExcludedFromCludo>
	);
};
