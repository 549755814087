import { usePageContext } from 'application/contexts/PageContext';
import { getSiblingsNavigation } from 'application/repositories/siblingNavigationRepository';
import { Navigation } from 'designsystem/components/3-organisms/Navigation';
import { LayoutStyle } from 'features/Layouts/Layout/LayoutFeature';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { LinkFeature } from '../Link';

export const SiblingNavigationFeature = () => {
	const [navItems, setNavItems] = useState(null);
	const [parentName, setParentName] = useState(null);
	const pageContext = usePageContext();
	const router = useRouter();
	const layout = (router?.query?.layout as LayoutStyle) ?? null;
	const { parentId, content, url: currentUrl } = pageContext ?? {};

	useEffect(() => {
		const get = async () => {
			const navigationObject = await getSiblingsNavigation(parentId, pageContext);
			const navigationItems = navigationObject.navigationItems;
			const navigationItemsWithLink = navigationItems
				?.filter((item) => !item.hidden)
				.map((item) => {
					const isCurrent = currentUrl === item.url;
					return {
						...item,
						active: isCurrent,
						link: <LinkFeature url={item.url} name={item.title || item.name} aria-current={isCurrent ? 'page' : undefined} />,
					};
				});
			setNavItems(navigationItemsWithLink);
			setParentName(navigationObject.parentName);
		};
		get();
	}, [parentId, pageContext, currentUrl]);

	if (content?.hideSiblingMenu) {
		return null;
	}

	return navItems?.length > 1 && content?.umbracoNaviHide === false && layout !== 'simple' ? (
		<Navigation data-tracking-id="siblingNavigation" style="list" heading={parentName} navigationItems={navItems} className="u-print-none" />
	) : null;
};
