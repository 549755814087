import classNames from 'classnames';
import { ArticleHeader } from 'components/2-molecules/Article';
import { Container, ContainerWidth } from 'components/4-habitats/Container';
import { TopArea, TopAreaProps } from 'components/4-habitats/TopArea';
import styles from './ArticlePage.module.scss';
export interface ArticlePageProps {
	aside?: React.ReactNode;
	additionalTop?: TopAreaProps['additional'];
	additionalPageContent?: React.ReactNode;
	containsAside?: boolean;
	children?: React.ReactNode;
	heading: string;
	teaser: string;
	topBanner?: React.ReactNode;
	pageWidth?: ContainerWidth;
}

export const ArticlePage: React.FC<ArticlePageProps> = ({
	aside,
	additionalTop,
	additionalPageContent,
	containsAside,
	children,
	heading,
	teaser,
	topBanner,
	pageWidth = 'Medium',
}) => {
	return (
		<div className={classNames(styles.ArticlePage, 'template-article-page')}>
			<TopArea additional={{ top: additionalTop.top }} topBanner={topBanner} />

			<Container className={classNames(styles.ArticlePage_container, containsAside ? 'u-aside' : '')} width={pageWidth} padded>
				<div
					className={classNames(
						styles.ArticlePage_wrapper,
						{ [styles.ArticlePage_wrapper___medium]: pageWidth === 'Medium' },
						{ [styles.ArticlePage_wrapper___large]: pageWidth === 'Large' },
						{ [styles.ArticlePage_wrapper___xlarge]: pageWidth === 'X-Large' },
					)}
				>
					{additionalPageContent}
					{additionalTop?.bottom && additionalTop.bottom}
				</div>
				<article className={styles.ArticlePage_content}>
					{heading && <ArticleHeader heading={heading} teaser={teaser} pageWidth={pageWidth} />}
					{children}
				</article>
				{aside}
			</Container>
		</div>
	);
};
