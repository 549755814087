import { useDictionaryContext } from 'application/contexts/DictionaryContext';
import { ArticlePage } from 'designsystem/components/5-templates/ArticlePage';
import { AsideFeature } from 'features/Modules/Aside';
import { SingleContentSectionFeature } from 'features/Modules/ContentSection/SingleContentSectionFeature';
import { ModuleFeature } from 'features/Modules/DynamicModule';
import { SiblingNavigationFeature } from 'features/Modules/SiblingNavigation';
import { TopBannerFeature } from 'features/Modules/TopBanner';
import NextLink from 'next/link';

export const ArticlePageFeature: React.FC<Content.ArticlePage> = ({ properties, additionalTop, additionalPageContent }) => {
	const { asideContent, pageSection, pageSections, heading, teaser, legalGuidanceLink, bannerContent } = properties ?? {};
	const dictionary = useDictionaryContext();
	const childProps = { noBackground: true };
	return (
		<ArticlePage
			additionalTop={additionalTop}
			additionalPageContent={additionalPageContent}
			containsAside
			heading={heading}
			teaser={teaser}
			topBanner={bannerContent?.length > 0 && <TopBannerFeature bannerContent={bannerContent} />}
			aside={
				<AsideFeature>
					<SiblingNavigationFeature />
					{asideContent ? asideContent.map((pageSection, index) => <ModuleFeature key={index} contentModule={pageSection} />) : null}
				</AsideFeature>
			}
		>
			<SingleContentSectionFeature pageSection={pageSection} noBackground={true} />
			{pageSections
				? pageSections.map((pageSection, index) => <ModuleFeature key={index} contentModule={pageSection} additionalProps={childProps} />)
				: null}
			{legalGuidanceLink && (
				<p>
					{dictionary.getExplainer('LegalGuidanceForMoreInfo')}{' '}
					<span data-tracking-id="legal_click">
						<NextLink href={legalGuidanceLink.url} target={legalGuidanceLink.target}>
							{dictionary.getExplainer('TheLegalGuidance') + ' ' + legalGuidanceLink.name}
						</NextLink>
					</span>
				</p>
			)}
		</ArticlePage>
	);
};
